<script setup lang="ts">
import { useTenant } from '~/composables/useTenant';
import { useTabStore } from '~/stores/useTabStore';

const tabStore = useTabStore();
useHead({
  title: 'Login',
})
definePageMeta({
  layout: 'login'
})

const route = useRoute()
const tenant = useState<any>('tenant').value
const displayTitle = ref('')
const successMessage = ref('');
const email = ref(route.query.email || '');
const password = ref(route.query.password || '');
const autologin = ref(route.query.autologin || false);
const windowPage = ref(1);
const resetEmail = ref('')
const loading = ref(false);
const loginError = ref('');
const resetError = ref('');

const emailRules = ref([
  (v: any) => !!v || 'Email is required',
  (v: any) => /.+@.+\..+/.test(v) || 'Email must be valid',
]);

const snackbar = ref({
  show: false,
  text: '',
  color: '',
  timeout: 3000
});

const showSnackbar = (message: string, color: string) => {
  snackbar.value.text = message;
  snackbar.value.color = color;
  snackbar.value.show = true;
};

const body = computed(() => (JSON.stringify({
  email: email.value,
  password: password.value,
  tenantId: tenant.id
})));
const user = await useUser();

onBeforeMount(async () => {
  try {
    const tenantState = await useTenant();
    const tenant = tenantState.value;

    if (tenant?.displayTitle) {
      displayTitle.value = tenant.displayTitle;
    }

    if (user.value?.id) {
      return navigateTo('/');
    }

    const token = useCookie('token').value;
    if (token) {
      try {
        const response = await $fetch('/api/v1/user/account/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        user.value = response;
        return navigateTo('/');
      } catch (error) {
        console.error('Failed to authenticate user:', error);
        // Clear 
        useCookie('token').value = '';
      }
    }
  } catch (error) {
    console.error('Failed to fetch tenant or authenticate user:', error);
  }

  if (autologin.value) {
    await onSubmit();
  }
});

async function onSubmit() {
  try {
    const response: any = await $fetch('/api/v1/user/auth/login', {
      method: 'POST',
      body: body.value,
    });
    user.value = usePick(response, ['id', 'email', 'name', 'tenant'])
    useCookie('token').value = response.token

    const tenantModules = response.tenant?.modules || [];
    if (tenantModules.includes('Birdnest') && tenantModules.length === 1) {
      tabStore.setDefaultTab('birdnest');
    } else {
      tabStore.clearDefaultTab();
    }

    navigateTo('/');
  } catch (error: any) {
    loginError.value = error.statusCode === 401 ? 'Invalid email or password' : 'Failed to login, please try again';
    showSnackbar(loginError.value, 'error');
  }
}

async function onReset() {
  try {
    loading.value = true;
    await $fetch('/api/v1/user/auth/reset_password', {
      method: 'POST',
      body: { email: resetEmail.value, tenantId: tenant.id },
    });
    successMessage.value = 'An email has been sent to reset your password.';
    showSnackbar(successMessage.value, 'success');
    resetError.value = '';

    setTimeout(() => {
      windowPage.value = 1;
      successMessage.value = '';
    }, 5000);

  } catch (error: any) {
    resetError.value = error.statusCode === 404 ? 'User not found' : 'Failed to reset password';
    showSnackbar(resetError.value, 'error');
    successMessage.value = '';
  } finally {
    loading.value = false;
  }
}

</script>

<template>
  <v-app>
    <v-main>
      <v-container class="loginP">
        <v-row justify="center" align="center">
          <v-col cols="12" md="6" class="d-flex flex-column align-center">
            <v-img :width="300" src="/Sustain-SBI.svg" class="mb-2 align-center sbi-logo" contain></v-img>
            <div v-if="displayTitle" class="mb-2 align-center">
              <h1>{{ displayTitle }}</h1>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="mx-auto">
              <!-- <v-card-title class="text-h5">{{ currentWindow }}</v-card-title> -->
              <v-window v-model="windowPage">
                <v-window-item :value="1">
                  <v-form class="pa-5">
                    <p class="label">Email</p>
                    <v-text-field v-model="email" required variant="outlined"></v-text-field>
                    <p class="label">Password</p>
                    <v-text-field v-model="password" required type="password" variant="outlined"></v-text-field>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <span @click="windowPage = 2" style="text-decoration: underline;color: #808B95;cursor: pointer;"
                        class="pr-5">Forgot password?</span>
                      <v-btn @click="onSubmit" class="login" variant="flat">
                        Login
                      </v-btn>
                    </v-card-actions>
                    <v-alert v-if="loginError" class="mt-2" type="error" color="error">{{ loginError }}</v-alert>
                  </v-form>
                </v-window-item>
                <v-window-item :value="2">
                  <v-form class="pa-5" @submit.prevent="onReset">
                    <v-card-title>Reset Password</v-card-title>
                    <v-card-text>
                      <v-text-field v-model="resetEmail" :rules="emailRules" color="primary" label="Email"
                        variant="outlined"></v-text-field>

                      <p class="note">**Do not forget to check SPAM box.</p>
                      <v-alert v-if="successMessage" class="mt-2" type="success" color="success">{{ successMessage
                        }}</v-alert>
                      <v-alert v-if="resetError" class="mt-2" type="error" color="error">{{ resetError }}</v-alert>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <span @click="windowPage = 1" style="color: #808B95;cursor: pointer;" class="pr-5">
                        <v-icon icon="mdi-chevron-left"></v-icon>Back
                      </span>
                      <v-btn type="submit" :loading="loading">
                        <template v-if="!loading">
                          Continue
                        </template>
                        <template v-else>
                          <v-progress-circular indeterminate size="24"></v-progress-circular>
                        </template>
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
        </v-row>
        <Snackbar :show="snackbar.show" :text="snackbar.text" :color="snackbar.color" :timeout="snackbar.timeout"
          @update:show="snackbar.show = $event" />
      </v-container>
    </v-main>
  </v-app>
</template>
<style scoped>
.loginP {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
}

.logo {
  font-family: 'Noto Sans';
  font-weight: 700;
  font-size: 1rem;
}

.label {
  color: #253779;
  font-family: 'Noto Sans';
  font-weight: 700;
}

.v-btn,
.login {
  background-color: #253779;
  color: white;
}

.sbi-logo {
  padding-bottom: 50px;
}

.note {
  color: red;
  font-size: 14px;
}
</style>